import React, { lazy, Suspense } from 'react'
import { LazyMotion, domAnimation, m } from 'framer-motion'
import { ButtonLink } from '../components/Button'
import config from '../../data/SiteConfig'
import aboutPointList from '../configs/about.config'
import { listVariants } from '../configs/style.config'
import { sanitizeUrl } from '../utils/global'

const Layout = lazy(() => import('../layout'))

const pageTitle = `About - ${config.siteTitle}`

function About({ location }) {
  // Sanitize location.href to prevent DOM XSS
  const safeHref = location?.href ? sanitizeUrl(location.href) : ''
  
  return (
    <Suspense fallback={<span />}>
      <Layout
        title={pageTitle}
        seoDescription="This page contains personal information about Ashmeet Sehgal"
        href={safeHref}
      >
        <div className="container">
          <h1>About Me</h1>
          <p>
            Greetings!, I&apos;m Ashmeet Sehgal, a passionate Software Engineer who finds joy in
            creating innovative solutions. Through my code and writings, I aspire to inspire and
            uplift people. This space serves as a collection of my learnings and creations accumulated
            over the years. I have a deep enthusiasm for side projects and take pride in assisting
            fellow developers, particularly those who share my background, in progressing within the
            tech industry. <br />
            Presently, I am fortunate to be working with a leading{' '}
            <ButtonLink
              simpleLink
              href="https://fortune.com/company/walmart/fortune500/"
              target="_blank"
              rel="noopener noreferrer"
              title="fortune 1 company"
            >
              Fortune #1 Company
            </ButtonLink>{' '}
            where I contribute to delivering an exceptional user experience with lightning-fast
            performance.
          </p>
          <p>The goal isn&apos;t to live forever, the goal is to create something that will.</p>
          <p>
            I derive great pleasure from experimenting with my content, particularly when exploring
            topics related to <em>Mental, Emotional, and Spiritual</em> health. My aim is to shine a
            light of hope for those who find themselves confined by darkness. If you believe I can be
            of assistance, I welcome you to book some time with me. Please feel free to get in touch.{' '}
            <ButtonLink
              simpleLink
              href="/contact"
              rel="external noopener noreferrer"
              target="_self"
              title="contact page"
            >
              Contact me
            </ButtonLink>
          </p>

          <p>
            In my spare time, I dedicate myself to learning new technologies, crafting non-fiction
            pieces, and mastering the piano.
          </p>

          <h3>To summarize:</h3>
          <ul>
            {aboutPointList.map((item, i) => (
              <LazyMotion features={domAnimation}>
                <m.li
                  forwardMotionProps={i}
                  key={item.icon}
                  animate="visible"
                  variants={listVariants}
                >
                  <span className="emoji">{item.icon}</span> {item.text}
                </m.li>
              </LazyMotion>
            ))}
          </ul>
        </div>
      </Layout>
    </Suspense>
  )
}

export default About
